//@flow
import * as React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Hero, {
  modelFourOhFourHero,
  type FourOhFourHeroData,
} from '../components/organisms/hero'
import SliceZone, {
  type SliceZoneData,
} from '../components/containers/sliceZone'

import { type PageMeta } from '../components/containers/seoData'

export type FourOhFourData = {
  prismic: {
    four_oh_four: {
      ...FourOhFourHeroData,
      body: SliceZoneData,
      seo_data: PageMeta[],
      _meta: {
        uid: string,
      },
    },
  },
}

function NotFoundPage({ data }: { data: FourOhFourData }): React.Node {
  const theme = 'main'
  const {
    _meta: { uid },
    seo_data,
    ...doc
  } = data.prismic.four_oh_four
  if (doc) {
    //$FlowFixMe
    const heroData = modelFourOhFourHero(doc, theme)
    return (
      <Layout path={uid} seoData={seo_data.slice(0, 1)[0]}>
        <Hero data={heroData}></Hero>
        <SliceZone>{doc.body}</SliceZone>
      </Layout>
    )
  }
  return null
}
//...GatsbyImageSharpFluid_withWebp
export const query = graphql`
  query NotFoundPageQuery {
    prismic {
      four_oh_four(lang: "en-gb", uid: "page-not-found") {
        _meta {
          uid
        }
        seo_data {
          meta_description
          meta_title
          opengraph_description
          opengraph_image
          opengraph_locale
          opengraph_site_name
          opengraph_title
          twitter_card_type
          twitter_site
        }
        subheading
        heading
        backgrounds {
          theme {
            ... on PRISMIC_Theme {
              fourohfour_image
              fourohfour_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        body {
          ... on PRISMIC_Four_oh_fourBodyCta {
            type
            label
            primary {
              testimonial {
                ... on PRISMIC_Testimonials {
                  name
                  location
                  testimonial
                }
              }
              section_title
              description
              button_text
              button_link {
                _linkType
              }
            }
          }
          ... on PRISMIC_Four_oh_fourBodyService_grid {
            type
            fields {
              description
              service_name
              link {
                _linkType
                ... on PRISMIC_Services {
                  _meta {
                    uid
                    id
                    type
                  }
                  theme {
                    ... on PRISMIC_Theme {
                      _meta {
                        uid
                      }
                    }
                  }
                }
                ... on PRISMIC__ExternalLink {
                  _linkType
                }
                ... on PRISMIC__FileLink {
                  _linkType
                }
              }
              service_image
              service_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 480) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            primary {
              heading
            }
          }
        }
      }
    }
  }
`

export default NotFoundPage
